import React from 'react'
import posed from 'react-pose'
import styled from 'styled-components'
import { Link } from 'gatsby'

const Container = styled.div`
  position: absolute;
  height: 100%;
`

const Navigation = styled.nav`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 20px;
  transition: background 250ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  @media (max-width: 800px) {
    padding: 20px;
  }

  svg {
    position: relative;
    width: 24px;
  }

  &.active {
    background: #242d38;
    .attention {
      background: #2f76c9;
      span {
        color: white;
      }
      &:hover {
        background: white;
        span {
          color: #2f76c9;
        }
      }
    }
  }
  .attention {
    background: white;
    span {
      color: #2f76c9;
    }
  }
  .navLinks {
    height: 100%;
    position: absolute;
    right: 0;
    @media (max-width: 800px) {
      display: none;
    }
    a {
      display: inline-block;
      padding: 0 30px;
      color: white;
      text-transform: uppercase;
      position: relative;
      top: 50.1%;
      height: 100%;
      transform: translateY(-50%);
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      transition: color 250ms ease-in-out, background 250ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
      &.attention {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 0;
      }
      &:hover {
        background: white;
        color: #2f76c9;
      }
      span {
        display: inline-block;
        position: relative;
        top: 50%;
        transform: translateY(-55%);
        font-size: 0.89em;
        letter-spacing: 0.05em;
      }
    }
  }
`

const MenuAnimate = posed.div({
  idle: { x: 0.0 },
  hovered: { x: 7.0 }
})

const AnimateSpan = posed.div({
  idle: { x: 1.0, scale: 1.0 },
  hovered: { x: 8.0, scale: 1.1 }
})

const MenuToggle = styled(MenuAnimate)`
  display: none;
  align-items: center;
  position: relative;
  width: 100px;
  z-index: 4;
  font-family: gotham-bold;
  &:hover {
    cursor: pointer;
  }
  .label {
    color: white;
    padding-left: 14px;
  }
  @media (max-width: 800px) {
    display: block;
  }
`

const MobileNav = styled.div`
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: #2f76c9;
  text-align: center;
  opacity: 0;
  z-index: 4;
  transition: opacity 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95), left 150ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  display: none;
  @media (max-width: 800px) {
    display: block;
  }
  &.active {
    opacity: 1;
    left: 0;
  }
  a {
    display: inline-block;
    width: 100%;
    color: white;
    padding: 30px 0;
    font-size: 1.4em;
    transition: background 150ms cubic-bezier(0.445, 0.05, 0.55, 0.95), color 150ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    &:hover {
      background: white;
      color: #2f76c9;
    }
    &.attention {
      background: rgba(255, 255, 255, 0.1);
      color: white;
      &:hover {
        background: white;
        color: #2f76c9;
      }
    }
  }
`

const Wrapper = styled.div`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`

class Nav extends React.Component {
  state = { hovering: false, open: false, active: false }

  componentDidMount() {
    document.getElementById('MenuToggle').addEventListener('click', this.MenuOpen)
    window.addEventListener('scroll', this.handleScroll)
  }

  toggleNav = event => {
    document.getElementById('mobileNav').classList.toggle('active')
  }

  handleScroll = event => {
    const scrollTop = window.pageYOffset
    if (scrollTop > 50) {
      this.setState({ hasScrolled: true })
    } else {
      this.setState({ hasScrolled: false })
    }
  }

  render() {
    return (
      <Container>
        <MobileNav id="mobileNav">
          <Wrapper>
            <Link to="/products">
              <span>Products</span>
            </Link>
            <Link to="/about">
              <span>About Us</span>
            </Link>
            <Link to="/contact">
              <span>Contact</span>
            </Link>
            <Link to="/products/foundation/">
              <span>Foundation</span>
            </Link>
            <Link className="attention" to="/jobs">
              <span>Join Us</span>
            </Link>
          </Wrapper>
        </MobileNav>

        <Navigation className={this.state.hasScrolled ? 'nav active' : 'nav'}>
          <MenuToggle
            id="MenuToggle"
            pose={this.state.hovering ? 'hovered' : 'idle'}
            onClick={this.toggleNav}
            onMouseEnter={() => this.setState({ hovering: true })}
            onMouseLeave={() => this.setState({ hovering: false })}
          >
            <svg width="20px" height="20px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="menuIcon" fill="#FFFFFF" fillRule="nonzero">
                  <g id="path-0-link" transform="translate(0.000000, 8.000000)">
                    <path
                      d="M13.9791247,0.908048932 L1.52850214,0.908048932 C0.6947737,0.908048932 0,1.61503694 0,2.46342256 C0,3.31180817 0.6947737,4.01879618 1.52850214,4.01879618 L14.006082,4.01879618 C14.8398104,4.01879618 15.5345841,3.31180817 15.5345841,2.46342256 C15.5074443,1.58630495 14.8398104,0.908048932 13.9791247,0.908048932 Z"
                      id="path-0"
                    />
                  </g>
                  <g id="path-1-link">
                    <path
                      d="M18.8425406,0 L1.52850214,0 C0.6947737,0 0,0.706988011 0,1.55537362 C0,2.40375924 0.6947737,3.11074725 1.52850214,3.11074725 L18.8694979,3.11074725 C19.7032263,3.11074725 20.398,2.40375924 20.398,1.55537362 C20.3708602,0.706988011 19.7032263,0 18.8425406,0 Z"
                      id="path-1"
                    />
                  </g>
                  <g id="path-2-link" transform="translate(0.000000, 17.000000)">
                    <path
                      d="M18.8425406,0.787252754 L1.52850214,0.787252754 C0.6947737,0.787252754 0,1.49424076 0,2.34262638 C0,3.19101199 0.6947737,3.898 1.52850214,3.898 L18.8694979,3.898 C19.7032263,3.898 20.398,3.19101199 20.398,2.34262638 C20.3708602,1.49424076 19.7032263,0.787252754 18.8425406,0.787252754 Z"
                      id="path-2"
                    />
                  </g>
                </g>
              </g>
            </svg>

            <AnimateSpan
              className="label"
              pose={this.state.hovering ? 'hovered' : 'idle'}
              onMouseEnter={() => this.setState({ hovering: true })}
              onMouseLeave={() => this.setState({ hovering: false })}
            />
          </MenuToggle>

          <div>
            <Link to="/">
              <svg viewBox="0 0 30 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M29.0311 3.78814C29.0311 1.27966 28.5527 0.0254235 25.5377 0.0254235L13.4777 0C4.43266 0 -0.0597012 3.35593 0.000599008 10.0678C-0.019501 16.9322 4.52311 20.3475 13.6284 20.3136H16.553C19.5278 20.3475 19.9861 21.2034 19.9861 22.8814C19.9459 24.6102 19.4575 25.4746 16.4625 25.4746H4.28191C1.3875 25.4915 0.959372 26.822 0.939272 29.4661C0.959372 31.822 1.39755 32.9915 4.31206 32.9746L16.5228 33C25.4674 32.9831 29.9597 29.5932 29.9999 22.8305C30.02 16.1695 25.5277 12.7797 16.5228 12.661H13.5983C10.5029 12.661 9.9843 11.8475 9.9843 10.2203C9.9843 8.49152 10.4928 7.62712 13.5681 7.62712H25.6583C28.5326 7.57627 28.9708 6.29661 29.0311 3.78814Z"
                  fill="white"
                />
              </svg>
            </Link>
          </div>

          <div className="navLinks">
            <Link to="/products">
              <span>Products</span>
            </Link>
            <Link to="/about">
              <span>About Us</span>
            </Link>
            <Link to="/contact">
              <span>Contact</span>
            </Link>
            <Link to="/products/foundation/">
              <span>Foundation</span>
            </Link>
            <Link className="attention" to="/jobs">
              <span>Join Us</span>
            </Link>
          </div>
        </Navigation>
      </Container>

      
    )
  }
}

export default Nav
