import React from 'react'
import styled from 'styled-components'

import { Link } from 'gatsby'

const FooterBlock = styled.footer`
  text-align: center;
  padding-top: 80px;
  .links {
    display: flex;
    justify-content: space-around;
    width: 90%;
    margin: 0 auto;
    max-width: 560px;
    font-size: 0.9em;
    padding: 30px 0;
    @media (max-width: 800px) {
      display: none;
    }
    a {
      color: white;
      text-transform: uppercase;
      opacity: 0.5;
      transition: opacity 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
      &:hover {
        opacity: 1;
      }
    }
  }
  .copyright {
    display: inline-block;
    padding: 30px;
    opacity: 0.3;
    font-size: 0.9em;
    text-transform: uppercase;
    font-family: nunitoReg, sans-serif;
  }
`

const Social = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  max-width: 140px;
  @media (max-width: 800px) {
    padding-top: 35px;
  }
`

function Footer() {
  return (
    <FooterBlock>
      <Link to="/">
        <svg width="35" height="35" viewBox="0 0 49 52" fill="none">
          <path
            d="M47.4175 5.96918C47.4175 2.01644 46.6361 0.0400613 41.7116 0.0400613L22.0136 0C7.24001 0 -0.0975119 5.28814 0.00097838 15.8644C-0.0318517 26.6811 7.38775 32.0627 22.2598 32.0092H27.0366C31.8954 32.0627 32.6439 33.4114 32.6439 36.0555C32.5783 38.7797 31.7805 40.1418 26.8888 40.1418H6.99379C2.26625 40.1685 1.56697 42.265 1.53414 46.4314C1.56697 50.1438 2.28267 51.9866 7.04303 51.9599L26.9873 52C41.5967 51.9733 48.9342 46.6317 48.9999 35.9753C49.0327 25.4792 41.6952 20.1376 26.9873 19.9507H22.2105C17.1547 19.9507 16.3077 18.6687 16.3077 16.1048C16.3077 13.3806 17.1383 12.0185 22.1613 12.0185H41.9086C46.6033 11.9384 47.319 9.92193 47.4175 5.96918Z"
            fill="white"
          />
        </svg>
      </Link>

      <div className="links">
        <Link to="/products">Products</Link>
        <Link to="/about">About</Link>
        <Link to="contact">Contact</Link>
        <Link to="/jobs">Jobs</Link>
        <Link to="/products/foundation/">Foundation</Link>
        <Link to="/privacy-policy">Privacy Policy</Link>
      </div>

      <Social>
        <div>
          <a href="https://twitter.com/splendo" target="_blank" rel="noopener noreferrer">
            <svg width="30" height="30" viewBox="0 0 39 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M38.2597 4.56796C36.8576 5.19881 35.3604 5.62827 33.78 5.82238C35.3889 4.83486 36.6271 3.27473 37.2093 1.41616C35.7026 2.3139 34.0319 2.969 32.2543 3.33295C30.8332 1.7801 28.8084 0.80957 26.5603 0.80957C22.2541 0.80957 18.7606 4.37627 18.7606 8.76793C18.7606 9.39878 18.8319 10.0054 18.9626 10.5877C12.4795 10.2723 6.73082 7.09376 2.88328 2.28963C2.20836 3.45669 1.82811 4.81545 1.82811 6.29551C1.82811 9.06153 3.20648 11.4951 5.29779 12.9242C4.01924 12.883 2.81435 12.5239 1.76394 11.9294V12.0265C1.76394 15.8844 4.44937 19.1065 8.02124 19.8393C7.36533 20.0188 6.67615 20.1159 5.96558 20.1159C5.46651 20.1159 4.99122 20.0674 4.51592 19.9752C5.51404 23.1391 8.38959 25.4417 11.8117 25.5072C9.15007 27.6424 5.77547 28.9138 2.13943 28.9138C1.52155 28.9138 0.903655 28.8774 0.285767 28.8046C3.75544 31.0611 7.843 32.381 12.2633 32.381C26.6173 32.381 34.4597 20.2493 34.4597 9.74332C34.4597 9.40364 34.4597 9.06395 34.4359 8.72426C35.9569 7.60815 37.2877 6.20087 38.3334 4.5995L38.2597 4.56796Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="38.0476" height="31.5714" fill="white" transform="translate(0.285767 0.80957)" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>

        <div>
          <a href="https://www.facebook.com/splendohealth/" target="_blank" rel="noopener noreferrer">
            <svg width="30" height="30" viewBox="0 0 267 267" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M262.305 248.082C262.305 255.938 255.936 262.307 248.082 262.307H182.409V162.504H215.908L220.924 123.609H182.409V98.7772C182.409 87.5162 185.536 79.8422 201.684 79.8422L222.28 79.8332V45.0452C218.718 44.5712 206.492 43.5122 192.268 43.5122C162.573 43.5122 142.243 61.6382 142.243 94.9252V123.609H108.658V162.504H142.243V262.307H18.812C10.954 262.307 4.58801 255.937 4.58801 248.082V18.8119C4.58801 10.9549 10.955 4.58789 18.812 4.58789H248.082C255.937 4.58789 262.305 10.9549 262.305 18.8119V248.082Z"
                fill="white"
              />
            </svg>
          </a>
        </div>
      </Social>

      <span className="copyright">© Copyright 2023, Splendo</span>
    </FooterBlock>
  )
}

export default Footer
